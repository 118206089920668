import React from 'react'
import styled from 'styled-components'
import Layout from '../components/Layout'
import { Wrapper } from '../components/Wrapper'
import { fontSizeHeading1, colorLightGray, colorDarkGray, fontSizeSm } from '../components/Variables/Variables'
import { Row, Col } from '../components/Layout/Grid'
import Btn from '../components/Button/Btn'
import { media } from '../components/Utilities/Media'

const ContactBanner = styled.div`
    background: url(${require('../images/contact-temp.jpg')});
    height: 350px;
    position: relative;
    &::before {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0,0,0,0.1);
        content: '';
    }
    div {
        position: relative;
        color: white;
        text-align: center;
        h1 {
            font-weight: bold;
            font-size: ${ fontSizeHeading1 };
        }
        p {
            margin-top: 10px;
            letter-spacing: 0.025em;
        }
    }
`

const ContactForm = styled.div`
    margin-top: 50px;
    input, textarea {
        -webkit-appearance: none;
        border: 1px solid ${colorLightGray};
        width: 100%;
        padding: 12px 15px;
    }
    label {
        margin: 25px 0 5px;
        display: inline-block;
        &:first-of-type {
            margin-top: 0;
        }
    }
    button {
        margin-top: 25px;
    }
    > div {
        align-items: initial;
    }

    h3 {
        font-size: 32px;
        display: inline-block;
        &::after {
            content: "";
            display: inline-block;
            width: 15px;
            height: 15px;
            border-top: 2px solid #00a3e9;
            border-right: 2px solid #00a3e9;
            transform: rotate(45deg);
            margin-left: 15px;
            cursor: pointer;
        }
    }

    h4 {
        font-size: 22px;
        font-weight: bold;
        margin-bottom: 11px;
        margin-top: 30px;
    }
    p {
        color: ${colorDarkGray};
        font-size: ${fontSizeSm};
        line-height: 1.5;
    }

`
const ContactItext = styled.input.attrs(props => ({
    type: 'text'
}))`
`
const ContactItextarea = styled.textarea.attrs(props => ({ rows: 10 }))`
    resize: none;
`

const ContactLocation = styled.div`
    margin-top: 50px;
    iframe {
        height: 300px;
        width: 100%;
        display: block;
    }
`

const ContactSelect = styled.select`
    padding: 12px 15px;
    border-color: lightgray;
    -webkit-appearance: none;
    background: white url(${require('../images/arrow-icon.png')}) no-repeat 99%/4%;
    width: 100%;
`

const ContactCol = styled(Col)`
    @media ${media.md} {
        width: 50%;
    }    
    &:first-of-type {
        @media ${media.md} {
            > div {
                padding-top: 20px;
                padding-left: 32px;
            }
        }
    }
`

export const Contact = () => {
    return (
        <Layout>
            <ContactBanner>
                <Row vcenter hlign="center" height="100%">
                    <Col>
                        <h1>Get in touch</h1>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor.</p>
                    </Col>
                </Row>
            </ContactBanner>
            <Wrapper>
                <ContactForm>
                    <Row vlign="initial">
                        <ContactCol>
                            <h3 onClick={ () => alert("test") }>How to find Us?</h3>
                            <div>
                                <h4>Lorem Ipsum</h4>
                                <p>Lorem IpsumLorem Ipsum Lorem IpsumLorem Ipsum</p>
                                <h4>Lorem Ipsum</h4>
                                <p>Lorem IpsumLorem Ipsum Lorem IpsumLorem IpsumLorem IpsumLorem <br />Lorem IpsumLorem Ipsum Lorem IpsumLorem Ipsum</p>
                                <h4>Lorem Ipsum</h4>
                                <p>Lorem IpsumLorem Ipsum Lorem IpsumLorem Ipsum</p>
                                <h4>Lorem Ipsum</h4>
                                <p>Lorem IpsumLorem Ipsum Lorem IpsumLorem Ipsum</p>
                            </div>
                        </ContactCol>
                        <ContactCol>
                            <label>Full Name</label>
                            <ContactItext />

                            <label>Email</label>
                            <ContactItext />

                            <label>Inquiry type</label>
                            <ContactSelect>
                                <option disabled selected>Please select an option</option>
                                <option>test</option>
                                <option>test</option>
                                <option>test</option>
                            </ContactSelect>

                            <label>Message</label>
                            <ContactItextarea />

                            <Btn primary>Submit</Btn>
                        </ContactCol>
                    </Row>
                </ContactForm>
            </Wrapper>
            <ContactLocation>
                <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3861.8053259479707!2d121.0133761143663!3d14.55312238983269!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397c91211a83207%3A0xed1c23c154deccab!2sCocofed%20Bldg.!5e0!3m2!1sen!2sph!4v1571807949715!5m2!1sen!2sph" allowfullscreen=""></iframe>
            </ContactLocation>
        </Layout>
    )
}

export default Contact
